@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: "";
  --secondary-color: "";
  --tertiary-color: "";
  --quaternary-color: "";
}
.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}
.ant-btn-primary {
  background-color: #1677ff;
}
.ant-form-item {
  margin-bottom: 8px !important;
}
.ant-form-item-label {
  padding-bottom: 3px !important;
}
.react-flow__node {
  font-size: 16px !important;
  font-weight: 600;
}

.ant-table {
  border-top: 1px solid #d9d9a963;
  border-radius: 0 !important;
}

.ant-table-placeholder {
  z-index: 1000 !important;
}

.react-flow__attribution {
  display: none !important;
}

.ant-drawer-footer {
  border: none !important;
}

.ant-table-wrapper .ant-table.ant-table-bordered {
  border-top: 0 !important;
}

::-webkit-scrollbar {
  @apply w-[6px] h-1;
}

::-webkit-scrollbar-track {
  @apply bg-gray-300 rounded-[10px];
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-500 rounded;
}

.ant-menu {
  border-inline-end: none !important;
}
.ant-form-item-required {
  width: 100% !important;
}

@keyframes tab-to-center {
  100% {
    transform: translateX(0%);
  }
}

.tab-right-to-left {
  transform: translateX(-20%);
  animation: tab-to-center 300ms;
  animation-fill-mode: forwards;
}

.tab-left-to-right {
  transform: translateX(20%);
  animation: tab-to-center 300ms;
  animation-fill-mode: forwards;
}

.import-error-table-color
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #ff000046;
}

.custom-upload .ant-upload-select {
  width: 100% !important;
}

.custom-upload .ant-upload-select .ant-upload {
  display: block !important;
  width: 100% !important;
}

.custom-upload-tag .ant-upload-list-item-name {
  max-width: 6rem !important;
}

.custom-upload .ant-upload-list-item-thumbnail {
  cursor: default !important;
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.custom-modal .ant-modal-content {
  padding: 4px !important;
}

.order-steps .ant-steps-item-content {
  margin-top: 4px !important;
}

.order-steps .change-tail-color .ant-steps-item-tail::after {
  background-color: rgba(0, 0, 0, 0.253) !important;
}

.order-steps .ant-form-item {
  margin-bottom: 13px !important;
}

.order-steps .ant-upload-select {
  width: 100% !important;
}

.order-steps .ql-toolbar {
  border-radius: 6px 6px 0 0;
}

.order-steps .ql-container {
  border-radius: 0 0 6px 6px;
}

.order-steps .ant-card-body {
  padding-bottom: 0 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Fade in from bottom */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px); /* Fade out to top */
  }
}

.order-screen .ant-upload {
  width: 100%;
}

.ql-toolbar.ql-snow {
  border-radius: 6px 6px 0 0 !important;
}

.ql-container.ql-snow {
  border-radius: 0px 0px 6px 6px !important;
}

.custom-markdown .ant-row {
  height: 100%;
}

.custom-markdown .ant-col {
  height: 100%;
}

.custom-markdown .ant-form-item {
  height: 100%;
}

.custom-markdown .ant-form-item-control-input {
  height: 100%;
}

.custom-markdown .ant-form-item-control-input-content {
  height: 100%;
}

.home-page .ant-skeleton.ant-skeleton-element {
  width: 100% !important;
}

.home-page .ant-skeleton.ant-skeleton-element .ant-skeleton-image {
  width: 100% !important;
}

.custom-upload.ant-form-item {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.remove-form-margin .ant-form-item {
  margin-bottom: 0 !important;
}

.task-node {
  border-radius: 4px;
  color: #fff;
  align-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.checklist .ant-form-item-label > label {
  height: auto !important;
  padding-bottom: 6px;
}

.enquiry .ant-select-selection-search {
  padding-right: 20px !important;
}

body.dark-mode {
  background-color: #1b1d1e;
}
